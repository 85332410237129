@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto&family=Mulish&display=swap');

:root {
	--primary-color: #cff9f7;
	--secondary-color: #01b8b0;
	--white-color: #ffffff;
	--black-color: #404a53;
}
html {
	scroll-behavior: smooth;
}
body {
	overflow-x: hidden;
	background-color: #fff;
	font-family: 'Mulish', sans-serif;
}

* {
	margin: 0;
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
label,
input,
button,
ul,
li {
	margin: 0;
	padding: 0;
	line-height: 1.3;
}

/* ---------------- Navbar and burger menu ------------------ */
.navbar-container {
	height: fit-content;
	text-align: center;
	width: 100vw;
	z-index: 100;
	background-color: #fff;
}
.navbar-line {
	padding: 5px 0;
	font-size: 16px;
	letter-spacing: 0.5px;
}
.navbar-line-icon {
	color: #19a382;
	margin-right: 2px;
	font-size: 18px;
}
.navbar-contact-links {
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	border-top: 1px solid #3c3c3c;
}
.logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}
.logo-container h1 {
	font-size: 28px;
	color: #19a382;
	margin-right: 2px;
}
.logo-image-container {
	overflow: hidden;
	border-radius: 50%;
	padding: 5px;
	background-color: #fff;
}
.logo-image-container .logo-image {
	height: 40px;
	width: 40px;
}
.navbar-buttons-container {
	display: flex;
}
.navbar-buttons-container button:nth-child(1) {
	margin-right: 20px;
}
nav {
	width: 100%;
	height: 50px;
	background-color: #19a382;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0 30px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.burger-menu {
	display: none;
}

.menu .links {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	list-style: none;
	text-decoration: none;
}

.links .link {
	margin: 0px 5px;
	color: #fff;
	text-decoration: none;
	font-weight: normal;
	font-size: 15px;
	box-sizing: border-box;
	cursor: pointer;
	transform: scaleY(0);
	animation: showLinks 0.5s forwards 0.3s;
	padding: 5px 15px;
	border-radius: 10px;
	background: linear-gradient(#19a382 50%, #fff 0) 0 var(--_d, 0) / 100% 200%
			no-repeat,
		linear-gradient(#19a382 50%, #19a382 0) 0 var(--_d, 0) / 100% 200% no-repeat;
	transition: 0.8s;
}
.active,
.links .link:hover {
	--_d: 100%;
	color: #0a1930 !important;
}

.links Link {
	text-decoration: none;
}
@keyframes showLinks {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}

.container {
	padding-bottom: 100px;
}
.landing-container {
	width: 100vw;
	height: fit-content;
	text-align: center;
	background-color: #a2c7b6;
	padding: 40px 0;
	color: #000;
}
.landing-container h1,
.landing-container h2 {
	font-weight: 600;
	letter-spacing: 1px;
	margin: 20px 0;
	padding: 0px 5vw;
}
.landing-container p {
	font-size: 20px;
	padding: 20px 10vw;
	padding-bottom: 50px;
	text-align: center;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.div-container {
	width: 100vw;
	padding: 40px 20px;
	display: block;
	text-align: center;
}
.div-container h1,
.div-container h2 {
	font-weight: 600;
	letter-spacing: 1px;
}

/* ---------------- Homepage starts ----------------*/
.homepage-container {
	padding-bottom: 100px;
}
.homelanding-container {
	width: 100vw;
	height: fit-content;
	text-align: center;
	background-color: #a2c7b6;
	padding: 40px 0;
	color: #000;
}
.homelanding-container h1 {
	font-weight: 600;
	letter-spacing: 1px;
	margin: 20px 0;
	padding: 0px 5vw;
}
.homelanding-container p {
	font-size: 23px;
	padding: 20px 10vw;
	padding-bottom: 50px;
	text-align: center;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.homelanding-form {
	width: 100vw;
	padding: 30px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.homelanding-form h2 {
	font-weight: 600;
	letter-spacing: 1px;
	margin: 20px 0 30px 0;
	padding: 0px 5vw;
}
.homelanding-services-container {
	padding: 30px 0 0 0;
	width: 100vw;
	text-align: center;
}
.homelanding-services-container h2 {
	font-weight: 600;
	letter-spacing: 1px;
	margin: 20px 0 30px 0;
	padding: 0px 5vw;
}
.homelanding-services {
	padding: 30px 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 30px;
}
.homelanding-service {
	width: 250px;
	height: 180px;
	padding: 30px 70px;
	font-size: 24px;
	text-decoration: none;
	border: 2px solid #000;
	background-color: #19a382;
	border-radius: 10px;
	color: #fff;
	/* margin: 0 30px; */
	transition: all 0.5s ease-in-out;
}
.homelanding-service i {
	font-size: 30px;
}
.homelanding-service h4 {
	margin: 15px 0 0 0;
	letter-spacing: 1px;
}
/* .homelanding-service:nth-child(3) h4 {
	padding: 0 50px;
} */
.homelanding-service:hover {
	background-color: #fff;
	color: #19a382;
}
.team-container {
	width: 100vw;
	padding: 40px 20px;
	display: block;
	text-align: center;
}
.team-container h1 {
	font-weight: 600;
	letter-spacing: 1px;
}
.team-details {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.team-details h3 {
	width: 200px;
	height: 150px;
	padding: 20px;
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
	margin: 40px 50px;
	letter-spacing: 0.5px;
}
.team-card {
	margin: 40px;
}
.homepage-features-container {
	width: 100vw;
	padding: 40px 20px;
	display: block;
	text-align: center;
}
.homepage-features-container h1 {
	font-weight: 600;
	letter-spacing: 1px;
}
.homepage-features {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
}
.homepage-features-details,
.itrpage-requirement {
	text-align: left;
	padding: 40px;
}
.homepage-features-details h5,
.itrpage-requirement h5 {
	margin: 20px 0;
	letter-spacing: 0.5px;
}
.homepage-features-details h5 i,
.itrpage-requirement h5 i {
	font-size: 24px;
	margin: 0 5px;
	color: #19a382;
}

/* ----------------ITR page starts -------------------*/

.itrpage-container {
	padding-bottom: 100px;
}
.itrpage-landing-container,
.itrpage-features-container {
	width: 100vw;
	height: fit-content;
	text-align: center;
	background-color: #a2c7b6;
	padding: 40px 0;
	color: #000;
}

.itrpage-landing-container h1,
.itrpage-landing-container h2,
.itrpage-features-container h2 {
	font-weight: 600;
	letter-spacing: 1px;
	margin: 20px 0;
	padding: 0px 5vw;
}
.itrpage-landing-container p {
	font-size: 20px;
	padding: 10px 10vw;
	text-align: center;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.itrpage-landing-container h2 {
	margin-top: 40px;
}
.itrpage-landing-form {
	width: 100vw;
	padding: 30px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.itrpage-features {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 40px 5vw 20px 5vw;
}
.itrpage-feature {
	position: relative;
	width: 300px;
	height: 200px;
	text-align: center;
}
.itrpage-feature i {
	position: absolute;
	left: 40%;
	padding: 15px;
	display: block;
	height: 70px;
	width: 70px;
	background-color: #19a382;
	border-radius: 50%;
	color: #fff;
	font-size: 27px;
	float: center;
	transition: all 0.5s ease-in-out;
}
.itrpage-feature:hover.itrpage-feature i {
	background-color: #fff;
	color: #19a382;
}
.itrpage-feature h4 {
	margin-top: 100px;
	padding: 0 30px;
}
.itrpage-feature:nth-child(2) h4,
.itrpage-feature:nth-child(3) h4 {
	padding: 0 80px;
}

.itrpage-details-container,
.itrpage-steps-container,
.itrpage-requirement-container {
	width: 100vw;
	height: fit-content;
	text-align: center;
	background-color: #fff;
	padding: 40px 0;
	color: #000;
}
.itrpage-details-container h1,
.itrpage-steps-container h1,
.itrpage-requirement-container h1 {
	font-weight: 600;
	letter-spacing: 1px;
	margin: 20px 0;
	padding: 0px 5vw;
}
.itrpage-details-container p {
	font-size: 20px;
	padding: 10px 10vw;
	text-align: center;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.itrpage-requirements {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.itrpage-steps-container {
	padding: 40px 0 20px;
	background-color: #f5f8f7;
}
.itrpage-steps-container h1 {
	margin: 0;
}

/* ---------------- Property Valuation page starts ----------------*/
.propertypage-container {
	padding-bottom: 100px;
}
.propertypage-landing-container p {
	margin-bottom: 30px;
}
.propertypage-certificate-container {
	width: 100vw;
	height: fit-content;
	text-align: center;
	padding: 40px 20px;
	color: #000;
}
.propertypage-certificate-container-heading {
	font-weight: 600;
	letter-spacing: 1px;
	margin: 20px 0;
	padding: 0px;
}
.propertypage-certificate-details {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 0 0 0;
}
.propertypage-certificate-text {
	padding: 20px 40px 0 40px;
}
.propertypage-certificate-text p {
	max-width: 700px;
	text-align: left;
	font-size: 20px;
	padding-bottom: 30px;
}
.propertypage-doc-requirments-container {
	background-color: #fff;
}
.propertypage-summary-details {
	text-align: left;
	margin: 20px 0;
	letter-spacing: 0.5px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
}
.propertypage-summary-detail {
	max-width: 465px;
	margin: 10px;
}
.propertypage-summary-details1 {
	text-align: left;
	margin: 20px 0;
	letter-spacing: 0.5px;
}
.propertypage-summary-detail1 {
	margin: 10px;
}
.propertypage-summary-detail span,
.propertypage-summary-detail1 span {
	font-size: 20px;
}
.propertypage-summary-detail-title {
	font-weight: 600;
}
.propertypage-summary-end {
	text-align: left;
	font-size: 20px;
	margin: 10px;
}
.property-timeline-container {
	width: 100vw;
	height: fit-content;
	background-color: #a2c7b6;
	padding: 40px 0;
	color: #000;
}
.property-timeline-container h3 {
	font-weight: 600;
	text-align: center;
}

/*------------------CA Valuation Page starts--------------*/

.cavaluation-container {
	padding-bottom: 100px;
}
.capage-requirement-container {
	text-align: center;
	width: 100%;
	height: fit-content;
	padding: 40px 20px;
}
.capage-requirement-container h2,
.capage-report-summary-container h2 {
	font-weight: 600;
	letter-spacing: 1px;
	text-align: center;
}
.capage-requirements {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
}
.capage-requirement {
	text-align: left;
	padding: 20px 40px;
}
.capage-requirement h5 {
	margin: 20px 0;
	letter-spacing: 0.5px;
}
.capage-requirement h5 i {
	font-size: 24px;
	margin: 0 5px;
	color: #19a382;
}
.capage-report-container {
	border-top: 1px solid #c0c2c2;
	border-bottom: 1px solid #c0c2c2;
}
.capage-report-summary-container {
	width: 100%;
	height: fit-content;
	padding: 40px 20px;
}
.capage-report-summary-container .text {
	padding: 10px 6vw 10px 10vw;
	font-size: 20px;
}
.capage-report-summary-details {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0 0 0;
}
.capage-report-summary {
	margin: 0 30px;
	max-width: 60vw;
}
.capage-report-summary .text {
	padding: 10px 0;
}

/*----------------Horizontal Timeline-------------------*/
.hori-container {
	width: 100%;
	background: #a2c7b6;
	padding: 50px 10%;
	border-radius: 2px;
	position: relative;
	padding: 2em 0;
	margin-top: 3em;
	margin-bottom: 3em;
	display: flex;
	align-items: center;
	justify-content: center;
}
.hori-timeline-img {
	background-color: var(--color);
	top: 8px;
	border-radius: 50%;
	box-shadow: 0 0 0 4px #000, inset 0 2px 0 rgba(0, 0, 0, 0.08),
		0 3px 0 4px rgba(0, 0, 0, 0.05);
	width: 60px;
	height: 60px;
	left: 50%;
	margin-left: 40%;
	transform: translateZ(0);
	backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}
.hori-timeline-img i {
	font-size: 30px;
	position: relative;
	color: #000;
	top: 7px;
	left: 15px;
	z-index: 2;
}
.hori-timeline-content {
	position: relative;
	background: var(--color);
	border-radius: 2px;
	color: #fff;
	padding: 20px;
	max-width: 30vw;
	margin-top: 40px;
	margin: 50px 20px 0 20px;
	border: 4px solid #000;
	height: fit-content;
}
.hori-timeline-content h2 {
	color: rgba(255, 255, 255, 1);
	margin-top: 0;
	margin-bottom: 5px;
}
.hori-timeline-content p {
	color: rgba(255, 255, 255, 0.9);
	font-size: 13px;
	font-size: 0.9rem;
}
.hori-date,
.hori-date1 {
	display: inline-block;
	color: #fff;
	position: absolute;
	width: 95px;
	left: -95px;
	top: 10px;
	font-size: 16px;
	font-size: 1rem;
	color: #000;
	font-weight: 600;
	z-index: 1;
	padding: 5px 18px;
	background-color: var(--color);
	border-radius: 0px 10px 10px 0;
	border: 4px solid black;
}
.hori-date1 {
	border-radius: 10px 0px 0px 10px;
	left: 60px;
}
.hori-timeline-content p {
	margin: 1em 0;
	line-height: 1.6;
}
.hori-timeline-content::before {
	content: '';
	position: absolute;
	height: 0;
	width: 0;
	border: 10px solid transparent;
	top: -20px;
	left: 44%;
	transform: rotate(-90deg);
	border-color: transparent;
	border-left-color: #000;
}

/* ---------------- Contact page starts ----------------*/
.contactpage-landing-container {
	background-color: #fff !important;
}
.contactpage-details {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 0;
}
.contactpage-links {
	padding: 0 0 0 60px;
}
.contactpage-details h2 {
	padding: 0 2vw;
}

/*-----------------------Custom Form -----------------------*/
.custom-form {
	height: fit-content;
	width: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 0 40px 0;
}
.horizontal-form {
	flex-direction: row;
	width: 100%;
	padding: 0 20px;
	align-items: flex-end;
	flex-wrap: wrap;
}
.custom-form-input-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	position: relative;
}
.horizontal-form .custom-form-input-container {
	margin: 0 10px 0 10px;
}
.horizontal-contact-form .custom-form-input-container {
	margin: 20px 10px;
}
.custom-form-input-container label {
	font-size: 16px;
	letter-spacing: 0.5px;
	color: #fff;
	margin-bottom: 0;
	background-color: #19a382;
	border-radius: 5px 5px 0px 0px;
	padding: 5px 20px 5px 20px;
	width: fit-content;
	position: absolute;
	top: 0px;
}
.custom-form-input {
	outline: none;
	width: 330px;
	background-color: #fff;
	border: 2px solid #19a382;
	border-radius: 2px;
	padding: 10px 20px;
	margin-top: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: all 0.5s ease-in-out;
}
.horizontal-form .custom-form-input {
	width: 250px;
}
.horizontal-contact-form .custom-form-input {
	width: 280px;
}
.custom-form-submit {
	width: 330px;
	background-color: #19a382;
	color: #fff;
	border: 2px solid #19a382;
	border-radius: 2px;
	padding: 10px 20px;
	margin-top: 10px;
	transition: all 0.5s ease-in-out;
}
.horizontal-form .custom-form-submit {
	width: 200px;
	margin: 0 10px 0 10px;
}
.horizontal-contact-form .custom-form-submit {
	width: 280px;
	position: relative;
	top: -22px;
}
.custom-form-input::placeholder {
	transition: all 0.5s ease-in-out;
	font-size: 16px;
	letter-spacing: 1px;
}
.custom-form-input:focus {
	border-radius: 2px;
	background-color: #19a382;
	transition: all 0.5s ease-in-out;
	color: #fff;
}
.custom-form-input:focus::placeholder {
	font-size: 12px;
	color: #19a382;
	transition: all 0.5s ease-in-out;
}
.custom-form-submit:hover {
	background-color: #fff;
	color: #19a382;
}
.contact-button {
	width: fit-content;
	text-decoration: none;
	line-height: 1;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	background-color: #fff;
	border: none;
	cursor: pointer;
}
.contact-button-decor {
	position: absolute;
	inset: 0;
	background-color: #19a382;
	transform: translateX(-100%);
	transition: transform 0.3s;
	z-index: 0;
}
.contact-button-content {
	display: flex;
	align-items: center;
	font-weight: 600;
	position: relative;
	overflow: hidden;
}
.contact-button-icon {
	width: 48px;
	height: 40px;
	background-color: #19a382;
	color: #fff;
	display: grid;
	place-items: center;
	font-weight: 600;
	font-size: 20px;
}
.contact-button-text {
	display: inline-block;
	transition: color 0.2s;
	padding: 2px 1.5rem 2px;
	padding-left: 0.75rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.contact-button:hover .contact-button-text {
	color: #fff;
}
.contact-button:hover .contact-button-decor {
	transform: translate(0);
}

/*---------------------Custom Link ------------------------*/
.custom-link {
	width: fit-content;
	height: fit-content;
	display: block;
	overflow: hidden;
	background-color: #fff;
	border: none;
	cursor: pointer;
}
.custom-link-title {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.custom-link-decor {
	position: absolute;
	inset: 0;
	background-color: #19a382;
	transform: translateX(-100%);
	transition: transform 0.3s;
	z-index: 0;
}
.custom-link-title i {
	width: 48px;
	height: 40px;
	background-color: #19a382;
	color: #fff;
	display: grid;
	place-items: center;
	font-weight: 600;
	font-size: 20px;
}
.custom-link:hover .custom-link-decor {
	transform: translate(0);
}

/*---------------------------Company Card------------------------------*/

.company-card {
	height: fit-content;
	width: fit-content;
	background-color: #fff;
	border: 2px solid #000;
	border-radius: 20px;
	overflow: hidden;
}
.company-card-top {
	width: 100%;
	text-align: center;
	letter-spacing: 1px;
	position: relative;
	padding: 20px 00px 60px 0px;
	background-color: #fff;
}
.company-card-name {
	display: flex;
	align-items: center;
	justify-content: center;
}
.company-card-name img {
	height: 30px;
	width: 30px;
}
.company-card-name h2 {
	font-size: 20px;
	font-weight: 600;
	padding: 0;
}
.company-card-top h5 {
	position: absolute;
	font-size: 15px;
	bottom: 0;
	right: 0;
	padding: 5px 10px;
	background-color: #a2c7b6;
	color: #000;
	font-weight: 600;
	border-radius: 10px 0 0 0;
}
.company-card-top p {
	font-size: 15px;
}
.company-card-details {
	display: flex;
	flex-direction: column;
	background-color: #19a382;
	border-radius: 20px 0px 0px 0px;
	padding: 20px 0 35px 0;
	position: relative;
}
.company-card-details button {
	background-color: transparent;
	border: none;
	display: flex;
	padding: 10px 20px;
	color: #fff;
	font-size: 15px;
}
.company-card-details button i {
	margin-right: 3px;
}
.company-card-details span {
	position: absolute;
	bottom: 0;
	text-align: center;
	width: 100%;
	background-color: #fff;
	color: #000;
	padding: 3px;
	font-size: 12px;
}
.company-card-details span i {
	color: green;
	margin-right: 3px;
	font-size: 10px;
}

/*---------------------------Google Reviews----------------------*/
.google-reviews-container {
	height: fit-content;
	width: 100vw;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.google-reviews-container h2 {
	font-weight: 600;
	letter-spacing: 1;
}
.google-reviews {
	width: 70vw;
	padding: 15px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: #19a382;
	border: 2px solid #000;
	border-radius: 10px;
	margin: 30px 0;
}
.google-reviews-top {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 20px;
	border-radius: 10px;
	border: 2px solid #000;
	background-color: #fff;
}
.google-reviews-top p {
	letter-spacing: 1px;
	margin: -8px 0 0 50px;
	color: rgba(0, 0, 0, 0.7);
}
.google-reviews-company-name {
	display: flex;
	align-items: center;
	justify-content: center;
}
.google-reviews-company-name img {
	width: 50px;
	height: 50px;
}
.google-reviews-company-name h2 {
	font-weight: 600;
}
.google-reviews-company-rating {
	display: flex;
	align-items: center;
	margin: 0 0 0 30px;
}
.google-reviews-company-rating h3 {
	margin: 10px 5px 0 0;
	color: #fc0;
}
.google-reviews-company-rating span {
	margin: 15px 0 0 10px;
}
.google-reviews-details {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 20px;
	background-color: #fff;
	border-radius: 10px;
	margin-top: 15px;
	border: 2px solid #000;
}
.google-card {
	width: 220px;
	height: 214px;
	background: var(--color1);
	border-radius: 15px;
	box-shadow: 1px 5px 60px 0px var(--color2);
	margin: 20px 10px 0 10px;
	text-align: center;
	border: 2px solid #000;
}
.google-card .google-card-border-top {
	width: 60%;
	height: 3%;
	background: #000000;
	margin: auto;
	border-radius: 0px 0px 15px 15px;
}
.google-card span {
	font-weight: 600;
	color: #000;
	text-align: center;
	display: block;
	padding-top: 10px;
	font-size: 18px;
	letter-spacing: 0.5px;
}
.google-card .google-time {
	font-weight: 400;
	color: #ffffffe7;
	display: block;
	text-align: center;
	font-size: 13px;
	padding: 0 10px 10px 10px;
	letter-spacing: 0.2px;
}
.stars {
	--percent: calc(var(--rating) / 5 * 100%);
	display: inline-block;
	font-size: 35px;
	font-family: Times;
	line-height: 1;
}
.stars::before {
	content: '★★★★★';
	letter-spacing: 3px;
	background: linear-gradient(
		90deg,
		#fc0 var(--percent),
		var(--color1) var(--percent)
	);
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.company-stars {
	margin-top: 5px;
	font-size: 40px;
}

/*---------------------------ITR Timeline component------------------------------*/
.timeline-container {
	font-size: 18px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: #f5f8f7;
	padding: 40px 0 60px 0;
}
.timeline-item {
	position: relative;
	width: 100%;
	max-width: 25em;
	border-radius: 0.5em;
	padding: 20px;
	z-index: 1;
	transition: all 0.2s;
	cursor: pointer;
	overflow: hidden;
}
.timeline-item-numberWrap {
	position: absolute;
}
.timeline-item-number {
	font-family: 'maven pro', sans-serif;
	font-size: 13em;
	font-weight: 900;
	width: 0.9em;
	text-align: center;
}
.timeline-item-number {
	color: var(--color);
}
.timeline-item-coverWrap {
	transform: rotate(130deg);
	position: absolute;
	width: 18em;
	height: 15em;
	left: -3em;
	top: -1em;
}
.timeline-item-numberCover {
	position: absolute;
	background: #f5f8f7;
	width: 18em;
	height: 6em;
	border-radius: 50% 50% 0 0;
	border-bottom: 3px solid #f5f8f7;
	transition: all 0.4s;
}
.timeline-item-numberCover::before {
	position: absolute;
	content: '';
	bottom: 5px;
	left: 4em;
	right: 4em;
	top: 5em;
	box-shadow: 0 0 30px 17px #48668577;
	border-radius: 100px / 10px;
	z-index: -1;
}
.timeline-item-numberCover::after {
	position: absolute;
	bottom: 0;
	content: '';
	left: -10%;
	width: 120%;
	height: 150%;
	background: radial-gradient(at bottom, #48668533, transparent, transparent);
	z-index: 1;
}
.timeline-item-content {
	margin: 8em 3em 1em 7em;
	position: relative;
}
.timeline-item-content p {
	line-height: 1.5em;
	font-size: 20px;
}
.timeline-item:hover .timeline-item-numberCover {
	border-radius: 100%;
}
.timeline-item-icon {
	position: absolute;
	font-size: 40px;
	text-align: center;
	top: -1.5em;
	left: 50%;
	transform: translatex(-50%);
	color: var(--color);
}

/*---------------------------Team Card---------------------------*/

.card {
	width: 200px;
	height: 170px;
	border-radius: 10px;
	border: none;
	color: #fff;
	display: grid;
	cursor: pointer;
	grid-template-rows: 50px 1fr;
}
.card:hover .img-section {
	-webkit-transform: translateY(1em);
	-ms-transform: translateY(1em);
	transform: translateY(1em);
}
.card-desc {
	border-radius: 10px;
	padding: 15px;
	position: relative;
	top: -10px;
	display: grid;
	gap: 10px;
	background: var(--color1);
	height: 152px;
}
.card-time {
	font-size: 1.7em;
	font-weight: 500;
}
.img-section {
	-webkit-transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background: var(--color2);
}
.card-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}
.card-menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 4px;
	margin-inline: auto;
}
.card i {
	float: right;
	font-size: 50px;
	transform: rotate(45deg);
	margin-top: -10px;
	color: rgba(255, 255, 255, 0.8);
}
.card .dot {
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background: var(--color2);
}

/*---------------------------Footer------------------------------*/
.footer-container {
	width: 100vw;
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	border-top: 25px solid #19a382;
}
.footer-top {
	width: 80vw;
	height: fit-content;
	padding: 30px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #19a382;
	border-radius: 10px;
	color: #fff;
	position: absolute;
	top: -70px;
	z-index: 2;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
		rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.footer-top-head {
	letter-spacing: 0.5px;
}
.footer-top-links {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-top-links h4 {
	font-size: 20px;
}
.footer-top-link {
	display: flex;
}
.footer-social-link {
	color: #fff;
	position: relative;
	width: 40px;
	height: 40px;
	border: 2px solid #fff;
	border-radius: 100%;
	margin-left: 10px;
	background-color: #cd201f;
	transition: all 0.5s ease-in-out;
}
.footer-social-link:nth-child(2) {
	background: #f09433;
	background: -moz-linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	background: -webkit-linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
	background: linear-gradient(
		45deg,
		#f09433 0%,
		#e6683c 25%,
		#dc2743 50%,
		#cc2366 75%,
		#bc1888 100%
	);
}
.footer-social-link i {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -58%);
	font-size: 24px;
}
.footer-social-link:hover {
	background: #fff;
	background-color: #fff;
	color: #cd201f;
}

.footer-content {
	margin-top: 50px;
	padding: 40px;
	grid-row-gap: 5px;
	grid-column-gap: 5vw;
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto auto;
}

.footer-details,
.footer-services-links {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.footer-service-link {
	padding: 5px 10px;
	text-decoration: none;
	font-size: 18px;
	color: #000;
	letter-spacing: 0.3px;
	transition: all 0.3s ease-in-out;
	text-align: left;
	position: relative;
	margin-left: 20px;
}
.footer-service-link i {
	font-size: 23px;
	color: #19a382;
	margin-right: 0px;
	position: absolute;
	top: 0;
	left: -20px;
}
.footer-service-link:hover {
	color: #19a382;
}

.footer-link {
	background: transparent;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
	color: var(--color);
}
.footer-link-icon {
	border: 2px solid var(--color);
	padding: 13px 15px 15px 15px;
	margin-right: 10px;
	border-radius: 100px 100px 20px 100px;
	margin-right: 20px;
	transition: all 0.5s ease-in-out;
}
.footer-link i {
	font-size: 28px;
}
.footer-link-text {
	text-align: left;
	letter-spacing: 0.3px;
}
.footer-link-text h5 {
	font-weight: 900;
	font-size: 23px;
}
.footer-link-text p {
	padding: 0 !important;
}
.footer-link-icon:hover {
	background-color: var(--color);
	color: #fff;
}
.footer-bottom {
	width: 100vw;
	background-color: #19a382;
	padding: 10px;
	font-size: 18px;
	color: #fff;
	text-align: center;
}
.footer-heading {
	width: 100%;
	position: relative;
	margin: 0 auto 20px;
	padding: 10px 60px;
	text-align: center;
	background-color: #19a382;
	color: #fff;
}
.footer-heading::before,
.footer-heading::after {
	content: '';
	width: 60px;
	height: 110%;
	background-color: #fff;
	position: absolute;
	z-index: 1;
	top: -1px;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 25% 50%);
	background-image: linear-gradient(45deg, transparent 50%, transparent 50%);
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position: bottom right;
}
.footer-heading::before {
	left: -20px;
	transform: scaleX(-1);
}
.footer-heading::after {
	right: -20px;
}
.important-links {
	margin-top: 20px;
}
.contact-popup {
	display: none;
	align-items: center;
	justify-content: center;
	width: 100vw;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
}
.contact-popup.open {
	display: flex;
}
.contact-popup.close {
	display: none;
}
.contact-popup-details {
	background-color: #fff;
	padding: 20px;
	position: relative;
	border-radius: 10px;
	padding-top: 50px;
}
.close-button {
	font-size: 35px;
	position: absolute;
	top: 0px;
	right: 10px;
	border-radius: 50%;
	color: #19a382;
	z-index: 20;
	border: none;
	background-color: transparent;
	outline: none;
}
.contact-popup .custom-form {
	padding: 0;
}
.contact-popup .custom-form-input-container {
	margin-bottom: 20px;
}
.contact-popup .custom-form-input {
	width: 350px;
}
.contact-popup .custom-form-submit {
	width: 350px;
	margin-top: 20px;
	top: 0;
}
.contact-popup .footer-heading {
	margin-bottom: 40px;
}
.services-popup {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
}
.services-popup.open {
	clip-path: circle(100% at 50% 50%);
	transition: all 1s ease-in-out;
}
.services-popup.close {
	clip-path: circle(0% at 50% 50%);
	transition: all 1s ease-in-out;
}
.services-popup-details {
	background-color: #fff;
	padding: 20px;
	position: relative;
	border-radius: 10px;
	padding-top: 50px;
	max-height: 90vh;
	width: 90vw;
	overflow-x: hidden;
}
.close-button {
	font-size: 35px;
	position: absolute;
	top: 0px;
	right: 10px;
	border-radius: 50%;
	color: #19a382;
	z-index: 20;
	border: none;
	background-color: transparent;
	outline: none;
}
.services {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 0px 20px;
}
.services-div {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}
.services-popup-buttons {
	margin-top: 20px;
}
.services-button {
	background-color: #19a382;
	border: 2px solid #19a382;
	width: 300px;
	height: 50px;
	text-align: center;
	font-size: 20px;
	color: #fff;
	margin: 20px;
	transition: all 0.5s ease-in-out;
}
.services-button:hover {
	color: #19a382;
	background-color: #fff;
	transition: all 0.5s ease-in-out;
}
::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-thumb {
	background: #19a382;
	border-radius: 6px;
}
::-webkit-scrollbar-track {
	background: linear-gradient(#19a382, #fff);
	border-radius: 6px;
}
::selection {
	background: #19a382;
	color: #fff;
}

@media screen and (max-width: 1050px) {
	.services-popup-details {
		width: 95vw;
		overflow-y: scroll;
	}
	.navbar-contact-links {
		flex-direction: column;
		justify-content: center;
	}
	.footer-content {
		grid-column-gap: 2vw;
		grid-row-gap: 50px;
		grid-template-columns: 1fr 1fr;
		align-self: center;
	}
	.google-reviews {
		width: 100%;
	}
	.homepage-features,
	.itrpage-requirements {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
	}
	.homepage-features-details {
		text-align: left;
		padding: 40px 20px;
	}
	.homepage-features-details h5 i {
		font-size: 24px;
		margin: 0 5px 0 0;
	}
	.itrpage-features-container {
		padding: 0 0;
	}
	.itrpage-features {
		flex-direction: column;
		padding: 40px 2vw 20px 2vw;
	}
	.itrpage-feature:nth-child(1) h4 {
		padding: 0 80px;
	}
	.itrpage-feature:nth-child(2) h4 {
		padding: 0 90px;
	}
	.itrpage-landing-container p,
	.itrpage-details-container p {
		padding: 10px 20px;
	}
	.landing-container {
		padding: 40px 0px;
	}
	.landing-container h1,
	.landing-container h2 {
		margin: 20px 0;
		padding: 0;
	}
	.landing-container p {
		padding: 20px;
	}
	.propertypage-certificate-container {
		padding: 40px 0 0 0;
	}
	.propertypage-certificate-details {
		flex-direction: column;
	}
	.propertypage-certificate-text {
		padding: 20px 20px;
	}
	.propertypage-certificate-text p {
		max-width: 100%;
		text-align: center;
	}
	.contactpage-details {
		flex-direction: column-reverse;
		padding: 20px 0;
	}
	.contactpage-links {
		padding: 0 10px;
	}
}
@media screen and (max-width: 900px) {
	.footer-top {
		flex-direction: column;
		justify-content: center;
		top: -95px;
	}
	.footer-top-links {
		margin-top: 15px;
	}
	.footer-content {
		margin-top: 90px;
	}
	.horizontal-form {
		flex-direction: column;
		width: fit-content;
		padding: 0 0 40px 0;
		align-items: center;
	}
	.horizontal-form .custom-form-input-container {
		margin: 0 0 20px 0;
	}
	.horizontal-form .custom-form-input {
		width: 330px;
	}
	.horizontal-form .custom-form-submit {
		width: 330px;
		margin: 10px;
		top: 0;
	}
	.google-reviews-container {
		padding: 20px;
	}
	.google-reviews {
		align-items: center;
		justify-content: center;
		padding: 10px;
	}
	.google-reviews-top {
		flex-direction: column;
		align-items: center;
		padding: 15px 5px;
	}
	.google-reviews-company-name img {
		width: 40px;
		height: 40px;
	}
	.google-reviews-company-name h2 {
		font-size: 27px;
	}
	.google-reviews-company-rating {
		margin: 0;
	}
	.google-reviews-company-rating span {
		font-size: 14px;
	}
	.google-reviews-details {
		margin-top: 10px;
		padding: 15px 20px;
		align-items: center;
		justify-content: center;
	}
	.capage-requirements {
		flex-direction: column;
		padding: 20px 0;
	}
	.capage-requirement {
		padding: 0px 0px;
	}
	.capage-requirement h5 {
		position: relative;
		margin: 20px 0 0 30px;
	}
	.capage-requirement h5 i {
		position: absolute;
		top: -3px;
		left: -40px;
		font-size: 24px;
		margin: 0 5px;
		color: #19a382;
	}
	.capage-report-summary-container {
		padding: 40px 20px;
	}
	.capage-report-summary-container .text {
		padding: 10px 0;
		font-size: 18px;
	}
	.capage-report-summary-details {
		flex-direction: column-reverse;
	}
	.capage-report-summary {
		margin: 20px 0 0 0;
		max-width: 100vw;
	}
	.capage-report-summary .text {
		padding: 10px 0;
	}
}
@media screen and (max-width: 800px) {
	nav {
		align-items: center;
		justify-content: center;
		padding: 0;
	}
	.burger-menu {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}
	.burger-bar {
		height: 4px;
		width: 30px;
		background-color: #fff;
		border-radius: 10px;
		margin: 3px 0;
	}
	.menu {
		width: 100vw;
		background-color: #19a382;
		padding: 0;
		margin-right: 0px;
		position: absolute;
		top: 190px;
		animation: menuTranslate 0.3s forwards;
		z-index: 10;
	}
	.menu .links {
		width: 100vw;
		height: 100%;
		flex-direction: column;
		justify-content: center;
	}
	@keyframes menuTranslate {
		0% {
			transform: translateX(-50vw);
		}
		100% {
			transform: translateX(0vw);
		}
	}
	.links .link {
		margin: 20px 0;
		color: #fff;
		font-size: 18px;
	}
	.hidden {
		display: none;
	}
	.visible {
		display: inherit;
	}
	.burger-bar.clicked:nth-child(1) {
		width: 30px;
		transform: rotate(45deg) translate(5px, 5px);
		transition: ease-out 0.5s;
	}
	.burger-bar.clicked:nth-child(2) {
		transform: scale(0);
		transition: ease-out 0.5s;
	}
	.burger-bar.clicked:nth-child(3) {
		width: 30px;
		transform: rotate(135deg) translate(-8px, 10px);
		transition: ease-out 0.5s;
	}
	.burger-bar.unclicked {
		transform: rotate(0) translate(0);
		transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
	}
	.footer-content {
		grid-column-gap: 0;
		grid-template-columns: 1fr;
	}
	.homelanding-container h1,
	.homelanding-container h2 {
		margin: 0;
		padding: 20px 20px 30px 20px;
	}
	.homelanding-container p {
		padding: 10px 20px 50px;
	}
	.homelanding-services {
		/* flex-direction: column; */
	}
	.homelanding-service {
		width: 90vw;
		height: 150px;
		margin: 0px 0 20px 0px;
		padding: 30px;
	}
	/* .homelanding-service:nth-child(3) h4 {
		padding: 0 0px;
	} */
	.team-details {
		flex-direction: column;
	}
	.team-details h3 {
		width: 80vw;
		height: 100px;
		margin: 40px 0px 0 0;
	}
	.samplepage-details-container {
		padding: 40px 20px;
	}
	.samplepage-details-container h2 {
		padding: 0;
	}
	.samplepage-certificate {
		width: 95vw;
	}
	.hori-container {
		flex-direction: column;
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.hori-timeline-block {
		padding-bottom: 50px;
	}
	.hori-timeline-content {
		max-width: 90vw;
	}
}
@media screen and (max-width: 500px) {
	.logo-container h1 {
		font-size: 26px;
	}
	.navbar-buttons-container {
		flex-direction: column;
	}
	.navbar-buttons-container button:nth-child(1) {
		margin-right: 0px;
		margin: 10px 0;
	}
	.menu {
		top: 250px;
	}
	.footer-top {
		width: 90vw;
		top: -100px;
	}
	.footer-content {
		margin-top: 100px;
	}
	.footer-top-head h3 {
		font-size: 22px;
	}
	.footer-content {
		padding: 40px 20px;
	}
	.footer-heading {
		padding: 10px 50px;
	}
	.homepage-features-details {
		width: 100%;
	}
	.timeline-item-content {
		margin: 8em 1em 1em 7em;
	}
	.samplepage-report {
		height: 500px;
		width: 90vw;
	}
	.google-reviews-company-rating span {
		font-size: 12px;
		margin-left: 0;
	}
	.company-stars {
		font-size: 35px;
	}
}
